import { createClient, FilteredResponseQueryOptions, QueryWithoutParams } from 'next-sanity'

import { dataset, projectId, useCdn, token } from '../env'

export const client = createClient({
  projectId,
  dataset,
  useCdn,
  token,
  withCredentials: true, 
})

export const fetcher = async (query: string, params?: QueryWithoutParams, options?: FilteredResponseQueryOptions): Promise<any> => await client.fetch(query, params, { ...options, cache: 'no-store' }) ;
